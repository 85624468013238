<template>
  <li class="contact-person" id="{{ contact.id }}">
    <div class="person-image">
      <img
        class="round-image"
        v-if="!isDefault"
        :src="profilePic"
        :alt="contact.chatterName"
      />
      <img
        class="round-image"
        v-else
        src="@/images/default-avatar.png"
        :alt="contact.chatterName"
      />
      <p v-if="contact.unreadCount!=undefined" class="dot">{{contact.unreadCount[contact.position]}}</p>
    </div>
    <h3 class="person-name">{{ contact.chatterName }}</h3>
  </li>
</template>

<script>
import getUrlAndName from "@/composables/Storage/getUrlAndName";
import { ref, watchEffect } from "vue";

export default {
  props: ["contact"],
  async setup(props) {
    const profilePic = ref(null);
    const isDefault = ref(false);

    if (props.contact.chatterUid) {
      await getUrlAndName(profilePic, null, props.contact.chatterUid, "Images");
    } else {
      await getUrlAndName(profilePic, null, props.contact.id, "Images");
    }

    if (!profilePic.value) {
      isDefault.value = true;
    }

    return { profilePic, isDefault };
  },
};
</script>

<style>
.contact-person {
  display: flex;
  padding: 1vw;
  border-top: 1px solid #808080;
}

.contact-person:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

.person-image {
  position: relative;
  flex: 0 0 3vw;
  padding: auto;
  padding-right: 1vw;
}

.person-name {
  flex: 1 1 auto;
  align-self: center;
  font-weight: 400;
}

.unread .person-name{
  font-weight: 600 !important;
}

.dot {
  text-align: center;
  line-height: 1.2vw;
  font-size: 70%;
  font-weight: 600;
  color: white;
  height: 1.2vw;
  width: 1.2vw;
  display: none;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 2.2vw;
}

.unread .dot {
  display: inline;
}
</style>
