import { db } from '@/firebase/config'
import wrapWithCache from '@/composables/wrapWithCache'

const getAllProfile = async (store) => {
  try {
    store.value = [];
    await db.collection("Profiles").get().then(docs => {
      docs.forEach(doc => {
        store.value.push({ name: doc.data().firstName + " " + doc.data().lastName, id: doc.id })
      })
    })
    // console.log("getAllProfile activated")
  } catch (error) {
    alert("getAllProfile " + error)
  }

};

export default wrapWithCache(getAllProfile, "allProfiles")
